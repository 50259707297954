import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import API from "../API";
import useFHConnectUser from "./useFHConnectUser";
import checkIpOrLocalhost from "../checkIpOrLocalhost";
import { usePrivateLabel } from "./privateLabelContext";

export const UserContext = React.createContext({
  user: {
    token: "",
    exp: 0,
    permissions: [""],
  },
  singIn: async () => {},
  signInWithToken: async () => {},
  logoutUser: () => {},
  singUp: async () => {},
  loading: false,
  error: "",
  handleRefreshUser: () => {},
  brokerId: "",
  updateFormState: () => {},
  getCurrentFormState: () => {},
  clearFormState: () => {},
});

const useUser = () => {
  return useContext(UserContext);
};

/**
 * @author Giuliano Antonanzas
 */

export const UserCustomerContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const FHConnectUser = useFHConnectUser();

  const [user, setUser] = useState({
    token: localStorage.getItem("NFTdoorUserLOToken"),
    exp: localStorage.getItem("NFTdoorUserLOTokenExp"),
    permissions: localStorage.getItem("NFTdoorLOPermissions"),
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [brokerId, setBrokerId] = useState(null);
  const [privateLabelId, setPrivateLabel] = useState(null);
  const { privateLabel } = usePrivateLabel();

  const currentEnv = localStorage.getItem("currentEnvironment");
  const logout = useCallback(() => {
    localStorage.clear();
    localStorage.setItem("currentEnvironment", currentEnv);
  }, []);

  const logoutUser = () => {
    localStorage.removeItem("NFTdoorUserLOToken");
    localStorage.removeItem("NFTdoorLOPermissions");
    localStorage.setItem("NFTdoorUserLOTokenExp");
  };
  const globalFormStateRef = useRef({});

  const updateFormState = useCallback((newState) => {
    globalFormStateRef.current = { ...globalFormStateRef.current, ...newState };
  }, []);

  const getCurrentFormState = useCallback(() => {
    return globalFormStateRef.current;
  }, []);

  const clearFormState = useCallback(() => {
    globalFormStateRef.current = {};
  }, []);

  const singIn = useCallback(async (email, password) => {
    setLoading(true);
    /**@type {{data:{idToken:{jwtToken:string}}}} */
    const response = await API.post({
      url: "/login",
      data: {
        email,
        password,
        ...(checkIpOrLocalhost(window.location.hostname) && {
          domain:
            process.env.REACT_APP_PRIVATE_LABEL_DOMAIN ?? "test.nftydoor.com",
        }),
      },
    });
    if ("error" in response) {
      setLoading(false);
      setError(response.error);
    } else {
      setUser({
        token: response?.data?.result?.idToken?.jwtToken,
        exp: response?.data?.result?.idToken?.payload.exp * 1000,
        permissions: response?.data?.permissions,
      });
      setLoading(false);
      localStorage.setItem(
        "NFTdoorUserLOToken",
        response.data?.result?.idToken?.jwtToken
      );
      localStorage.setItem(
        "NFTdoorUserLOTokenExp",
        response.data?.result?.idToken?.payload.exp * 1000
      );
      localStorage.setItem(
        "NFTdoorLOPermissions",
        JSON.stringify(response?.data?.permissions)
      );
      localStorage.setItem("NFTYDoorUserLORole", response?.data?.role);
      if (response?.data?.role) {
        const userRole = response?.data?.role;
        const showOldDashboard =
          userRole === "8b231048-5e5d-41a2-ba86-f8cb8850e0c3" || //Loan Officer Default
          userRole === "10becc05-2afd-4de6-b33e-55cb41132dc3" || //HB/REMN LO
          userRole === "9bbeedc9-d5c7-47c9-8c21-6d83ee3cdf4a" || //Assistant
          userRole === "672ec121-4343-48e6-85ce-26d02722c1fa" || //Sub-User
          userRole === "2d6bc192-80f4-4455-b56b-341e6870f31e" || //Point of Contact
          userRole === "0eef768e-8311-11ee-b962-0242ac120007"; // PL loan officer

        if (showOldDashboard && privateLabel.isNFTYDoor)
          return navigate("/dashboard");
        else navigate("/home");
      } else {
        navigate("/");
      }
    }
  }, []);

  const handleLoadUser = () => {
    setLoading(true);
    if (user.token && Number(user.exp) > new Date().getTime()) {
      API.get({
        url: "/user",
      }).then((result) => {
        setUser((prev) => ({ ...prev, ...result?.data }));
        setBrokerId(result?.data?.brokerId);
        if (!brokerId) {
          setPrivateLabel(result?.data.privateLabel);
        }
        if (result?.data) {
          if (result?.data?.status === "archived") {
            logout();
            navigate("/login");
          } else {
            FHConnectUser(
              result.data.id,
              `${result.data.firstName} ${result.data.lastName}`,
              result.data.email,
              result.data.permissions || []
            );
          }
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  const handleRefreshUser = () => {
    handleLoadUser();
  };

  const singUp = useCallback(async (email, password, repeatPassword, code) => {
    setLoading(true);
    /**@type {{data:{idToken:{jwtToken:string}}}} */
    const response = await API.post({
      url: "/register",
      data: {
        password,
        repeatPassword,
        inviteCode: code,
      },
    });

    if ("error" in response) {
      setLoading(false);
      setError(response.error);
    } else {
      singIn(email, password);
    }
  }, []);

  useEffect(() => {
    handleLoadUser();
  }, [user.token, user.exp]);

  const signInWithToken = useCallback(
    async (user) => {
      const expiration =
        new Date().getTime() + parseInt(user.token.expires_in) * 1000;
      setUser({
        token: user.token.id_token,
        exp: expiration,
        permissions: user.permissions,
      });
      localStorage.setItem(
        "NFTdoorLOPermissions",
        JSON.stringify(user.permissions)
      );
      navigate("/home");
      window.location.reload();
    },
    [user]
  );

  useEffect(() => {
    handleLoadUser();
  }, [user.token, user.exp]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        logout,
        singIn,
        signInWithToken,
        singUp,
        loading,
        error,
        handleRefreshUser,
        logoutUser,
        brokerId,
        privateLabelId,
        updateFormState,
        getCurrentFormState,
        clearFormState,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default useUser;
